import { useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import "./MainDescription.css";

const MainDescription = ({ displayIndex }) => {
  const { darkMode } = useContext(DarkModeContext);

  const descriptionHeaders = [
    "More about me",
    "Help Me Chews",
    "Cubberd",
    "Dif-fur-ent Oppawtunity",
    "Gray Salmon Please",
    "My Tech Stacks",
  ];

  const descriptionContents = [
    "My Korean name is Sungyo Kim but you can call me Tommy. I recently started developing fullstack applications while studying at App Academy since July 2022. I find coding to be fascinating and am still amazed at what we can create with just text in a program.",
    "Help Me Chews is a full stack clone of Yelp (Reviews). In addition to default Yelp settings, an alternative form of reviews was implemented.",
    "Cubberd allows users to search for recipes based on ingredients used in a fun and interactive manner. This was a group project for which I played a large role as the frontend lead.",
    "Dif-fur-ent Oppawtunity is a web app proof of concept inspired by adoption sites. What makes this project unique is the inclusion of media from social visits and fosters in animal's profiles. These videos serve to catch the attention of potential adopters and better portray the animal's characteristics.",
    "Gray Salmon Please is a simple web site that explains how farmed salmon is naturally gray and how consumers influenced modern salmon color.",
    "Frontend: React-Redux, JavaScript, HTML, CSS, SCSS, TypeScript",
  ];

  const descriptionContentsSecondary = [
    "I am capable of both frontend and backend engineering but currently I find myself enjoying front-end more! I'm always trying to learn and find my place in this evolving environment. My preferred language is JS and I enjoy working with React.",
    "Technologies used: React frontend, Ruby on Rails backend, Yelp API for data fetching, and Google Maps API for places autocomplete and map rendering.",
    "Technologies used: MERN stack and Edamam API for recipe fetching.",
    "This project was made with vanilla Javascript and fetches data from a JSON file that houses placeholder information of pets. This enables a potential switch to a fullstack application that fetches data from existing shelters.",
    "This project was made using TypeScript, React, Redux and was set up using Vite instead of create react app. The purpose of this project was to familiarize myself with new languages while enhancing my frontend skills. A chart and an interactive slider were included to involve usage of props and JS libraries and to demonstrate CSS skills.",
    "Backend: Ruby, Ruby on Rails, RESTful APIs, postgreSQL, Express, Node.js, AWS",
  ];

  const descriptionLinks = [
    [],
    [
      "https://help-me-chews.herokuapp.com/",
      "https://github.com/sungyotkim/help-me-chews",
    ],
    ["https://cubberd.herokuapp.com/", "https://github.com/sungyotkim/Cubberd"],
    [
      "https://sungyotkim.github.io/Dif-fur-ent-Oppawtunity-Pet-Adoptions-And-Fosters/",
      "https://github.com/sungyotkim/Dif-fur-ent-Oppawtunity-Pet-Adoptions-And-Fosters",
    ],
    [
      "https://gray-salmon-please.web.app/",
      "https://github.com/sungyotkim/gray-salmon-please",
    ],
    [],
  ];

  return (
    <>
      <div
        className="main-description-container"
        style={darkMode ? { color: "white" } : { color: "black" }}
      >
        <div className="main-description-header disable-caret">
          {descriptionHeaders[displayIndex]}
        </div>
        <div
          className="main-description-content disable-caret"
          style={
            darkMode
              ? { borderLeft: "5px solid var(--secondary-light-four)" }
              : { borderLeft: "5px solid var(--main-dark-four)" }
          }
        >
          <p>{descriptionContents[displayIndex]}</p>
          <p>{descriptionContentsSecondary[displayIndex]}</p>
          {displayIndex === 5 && (
            <>
              <br />
              <p>
                Familiar with: jQuery, SQL, Heroku, Webpack, RSpec, Capybara,
                Jasmine, Chart.js, Firebase, Vite, git, google APIs
              </p>
            </>
          )}

          <div className="description-links">
            {descriptionLinks[displayIndex].map((link, i) => (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className={
                  darkMode
                    ? "description-link description-link-dark"
                    : "description-link"
                }
                style={
                  darkMode
                    ? { textDecorationColor: "var(--secondary-light-four)" }
                    : { textDecorationColor: "var(--main-dark-four)" }
                }
              >
                {i === 0 ? "View Site" : "Github"}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainDescription;
