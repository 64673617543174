import { useContext } from "react";
import "./ModeToggle.css";
import { FaRegMoon } from "react-icons/fa";
import { CgSun } from "react-icons/cg";
import { DarkModeContext } from "../../context/DarkModeContext";

const ModeToggle = () => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  const toggleDarkMode = (e) => {
    e.preventDefault();

    if (!darkMode) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  };

  return (
    <>
      <div className="toggle-btn-container" onClick={toggleDarkMode}>
        <div
          className={
            darkMode ? "toggle-btn-wrap wrap-toggled" : "toggle-btn-wrap"
          }
        >
          <div className={darkMode ? "toggle toggled" : "toggle"}>
            {darkMode && <FaRegMoon />}
            {!darkMode && <CgSun />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModeToggle;
