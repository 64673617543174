import { useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import Hero from "../Hero/Hero";
import MainDescriptions from "../MainDescriptions/MainDescriptions";
import ModeToggle from "../ModeToggle/ModeToggle";
import NavBar from "../NavBar/NavBar";
import "./LandingPage.css";
import Resume from "../../assets/Resume-Sungyo_Tommy_Kim.pdf";

const LandingPage = () => {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <>
      <div
        className="main"
        style={
          darkMode
            ? {
                backgroundColor: "var(--main-dark-one)",
              }
            : {
                backgroundColor: "var(--secondary-light-one)",
              }
        }
      >
        <div className="main-container">
          <div className="main-content-wrapper">
            <div
              className={
                darkMode ? "top-left-border border-dark" : "top-left-border"
              }
            >
              <svg
                width={300}
                height={300}
                style={{ pointerEvents: "none" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M 23 0 h 277 l -40 20 H 25 a 5 5 0 0 0 -5 5 v 235 L 0 300 V 12 C 0 6 6 0 12 0 Z"></path>
              </svg>
            </div>
            <div className="main-content">
              <NavBar />
              <Hero />
              <MainDescriptions />
            </div>
            <div
              className="website-info disable-caret"
              style={darkMode ? { color: "white" } : { color: "black" }}
            >
              <a href={Resume} target="_blank">
                View Resume
              </a>
            </div>
            <div
              className={
                darkMode
                  ? "bottom-right-border border-dark"
                  : "bottom-right-border"
              }
            >
              <svg
                width={300}
                height={300}
                style={{ pointerEvents: "none" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M 280 300 H 0 l 40 -20 h 235 a 5 5 0 0 0 5 -5 V 40 l 20 -40 v 288 C 300 294 296 300 288 300 Z"></path>
              </svg>
            </div>
          </div>
          <div className="dark-mode-toggle-container">
            <ModeToggle />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
