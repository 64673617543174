import { useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import "./HeroSlide.css";

const HeroSlide = ({ displaySlide }) => {
  const { darkMode } = useContext(DarkModeContext);

  const projectName = [
    "Hi, I'm Tommy",
    "Help Me Chews",
    "Cubberd",
    "Dif-fur-ent Oppawtunity",
  ];
  const descriptionArr = [
    "I'm a fullstack developer from New York City who also enjoys playing volleyball. These are my featured projects.",
    "A fullstack (React/Ruby on Rails) clone inspired by Yelp Reviews that incorporates an alternative review system concept.",
    "An interactive MERN stack web app that fetches recipes based on ingredients searched.",
    "A front-end vanilla JS single page web app/proof of concept that incorporates videos from fosters/visits to improve adoption rates.",
  ];
  const liveSiteLink = [
    "",
    "https://help-me-chews.herokuapp.com/",
    "https://cubberd.herokuapp.com/",
    "https://sungyotkim.github.io/Dif-fur-ent-Oppawtunity-Pet-Adoptions-And-Fosters/",
  ];

  return (
    <>
      <div className="hero-slide">
        <div className="hero-slide-content">
          {displaySlide === 0 && (
            <div className="text-container">
              <div className="hero-slide-header disable-caret">
                {projectName[displaySlide]}
              </div>
              <div className="hero-slide-description disable-caret">
                {descriptionArr[displaySlide]}
              </div>
            </div>
          )}
        </div>
        <div
          className={darkMode ? "hero-cover hero-cover-dark" : "hero-cover"}
        ></div>
        {displaySlide !== 0 && (
          <div
            className={
              darkMode ? "live-link-btn live-link-btn-dark" : "live-link-btn"
            }
            style={displaySlide === 1 ? { color: "white" } : { color: "black" }}
          >
            <a
              href={liveSiteLink[displaySlide]}
              target="_blank"
              rel="noreferrer"
            >
              {projectName[displaySlide]}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroSlide;
