import { useContext, useEffect } from "react";
import LandingPage from "./components/LandingPage/LandingPage";
import { DarkModeContext } from "./context/DarkModeContext";

function App() {
  const { setDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? setDarkMode(true)
      : setDarkMode(false);
  }, []);

  return (
    <>
      <LandingPage />
    </>
  );
}

export default App;
