import "./Hero.css";
import aboutSlide from "../../assets/tommy-volleyball-pic.jpg";
import helpMeChewsSlide from "../../assets/help-me-chews-pic.png";
import cubberdSlide from "../../assets/cubberd-pic.PNG";
import diffurentOppawtunitySlide from "../../assets/diffurent-oppawtunity-pic.PNG";
import { useContext, useEffect, useState } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import HeroSlide from "./HeroSlide";

const Hero = () => {
  const backgroundImages = [
    aboutSlide,
    helpMeChewsSlide,
    cubberdSlide,
    diffurentOppawtunitySlide,
  ];
  const [displayImage, setDisplayImage] = useState(backgroundImages[0]);
  const [displaySlide, setDisplaySlide] = useState(0);
  const [heroBarOne, setHeroBarOne] = useState(0);
  const [heroBarTwo, setHeroBarTwo] = useState(0);
  const [heroBarThree, setHeroBarThree] = useState(0);
  const [heroBarFour, setHeroBarFour] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [newIndex, setNewIndex] = useState();
  const { darkMode } = useContext(DarkModeContext);
  let tracker = 0;
  let i = 0;
  if (newIndex) {
    i = newIndex;
  }

  useEffect(() => {
    let interval = setInterval(() => {
      if (i === 3) {
        i = 0;
      } else {
        i += 1;
      }

      setDisplayImage(backgroundImages[i]);
      setDisplaySlide(i);
    }, 10000);

    if (clicked) {
      setClicked(false);
      clearInterval(interval);
      setInterval(interval, 10000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [clicked]);

  useEffect(() => {
    setHeroBarOne(0);
    setHeroBarTwo(0);
    setHeroBarThree(0);
    setHeroBarFour(0);
    tracker = 0;
    let firstSlideInterval = setInterval(() => {
      setHeroBarOne((old) => old + 1);
      tracker++;
      if (tracker === 100) {
        clearInterval(firstSlideInterval);
        tracker = 0;
      }
    }, 100);
    let secondSlideInterval = setInterval(() => {
      setHeroBarOne(100);
      setHeroBarTwo((old) => old + 1);
      tracker++;
      if (tracker === 100) {
        clearInterval(secondSlideInterval);
        tracker = 0;
      }
    }, 100);
    let thirdSlideInterval = setInterval(() => {
      setHeroBarOne(100);
      setHeroBarTwo(100);
      setHeroBarThree((old) => old + 1);
      tracker++;
      if (tracker === 100) {
        clearInterval(thirdSlideInterval);
        tracker = 0;
      }
    }, 100);
    let fourthSlideInterval = setInterval(() => {
      setHeroBarOne(100);
      setHeroBarTwo(100);
      setHeroBarThree(100);
      setHeroBarFour((old) => old + 1);
      tracker++;
      if (tracker === 100) {
        clearInterval(fourthSlideInterval);
        tracker = 0;
      }
    }, 100);

    if (displaySlide === 0) {
      clearInterval(secondSlideInterval);
      clearInterval(thirdSlideInterval);
      clearInterval(fourthSlideInterval);
    } else if (displaySlide === 1) {
      setHeroBarOne(100);
      clearInterval(firstSlideInterval);
      clearInterval(thirdSlideInterval);
      clearInterval(fourthSlideInterval);
    } else if (displaySlide === 2) {
      setHeroBarOne(100);
      setHeroBarTwo(100);
      clearInterval(firstSlideInterval);
      clearInterval(secondSlideInterval);
      clearInterval(fourthSlideInterval);
    } else if (displaySlide === 3) {
      setHeroBarOne(100);
      setHeroBarTwo(100);
      setHeroBarThree(100);
      clearInterval(firstSlideInterval);
      clearInterval(secondSlideInterval);
      clearInterval(thirdSlideInterval);
    }

    return () => {
      clearInterval(firstSlideInterval);
      clearInterval(secondSlideInterval);
      clearInterval(thirdSlideInterval);
      clearInterval(fourthSlideInterval);
    };
  }, [displaySlide]);

  const handleClick = (e, index) => {
    e.preventDefault();
    setNewIndex(index);
    setDisplayImage(backgroundImages[index]);
    setDisplaySlide(index);
    setClicked(true);
    tracker = 100;
    setHeroBarOne(0);
    setHeroBarTwo(0);
    setHeroBarThree(0);
    setHeroBarFour(0);
  };

  return (
    <>
      <div className="hero-container-wrapper">
        <div
          className="hero-container"
          style={{
            backgroundImage: `url(${displayImage})`,
          }}
          key={displayImage}
        >
          <div className="hero-slide-container">
            <HeroSlide displaySlide={displaySlide} />
          </div>
          <div className="hero-bars">
            <div
              className="hero-bar-clickable"
              onClick={(e) => handleClick(e, 0)}
            >
              <div className={darkMode ? "hero-bar dark-bar" : "hero-bar"}>
                <div
                  className={
                    darkMode ? "hero-bar-fill dark-bar-fill" : "hero-bar-fill"
                  }
                  style={{ width: `${heroBarOne}%` }}
                ></div>
              </div>
            </div>
            <div
              className="hero-bar-clickable"
              onClick={(e) => handleClick(e, 1)}
            >
              <div className={darkMode ? "hero-bar dark-bar" : "hero-bar"}>
                <div
                  className={
                    darkMode ? "hero-bar-fill dark-bar-fill" : "hero-bar-fill"
                  }
                  style={{ width: `${heroBarTwo}%` }}
                ></div>
              </div>
            </div>
            <div
              className="hero-bar-clickable"
              onClick={(e) => handleClick(e, 2)}
            >
              <div className={darkMode ? "hero-bar dark-bar" : "hero-bar"}>
                <div
                  className={
                    darkMode ? "hero-bar-fill dark-bar-fill" : "hero-bar-fill"
                  }
                  style={{ width: `${heroBarThree}%` }}
                ></div>
              </div>
            </div>
            <div
              className="hero-bar-clickable"
              onClick={(e) => handleClick(e, 3)}
            >
              <div className={darkMode ? "hero-bar dark-bar" : "hero-bar"}>
                <div
                  className={
                    darkMode ? "hero-bar-fill dark-bar-fill" : "hero-bar-fill"
                  }
                  style={{ width: `${heroBarFour}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
