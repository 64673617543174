import { useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import "./DescriptionNav.css";

const DescriptionNav = ({ displayIndex, setDisplayIndex }) => {
  const { darkMode } = useContext(DarkModeContext);

  const handleDisplayIndex = (e, index) => {
    e.preventDefault();

    if (displayIndex === index) {
      setDisplayIndex(0);
    } else {
      setDisplayIndex(index);
    }
  };

  return (
    <>
      <div
        className="description-nav-container"
        style={darkMode ? { color: "white" } : { color: "black" }}
      >
        <div className="description-nav-header disable-caret">Projects</div>
        <div
          className="description-nav-items disable-caret"
          style={
            darkMode
              ? { borderLeft: "5px solid var(--secondary-light-four)" }
              : { borderLeft: "5px solid var(--main-dark-four)" }
          }
        >
          <div
            className="description-nav-item"
            onClick={(e) => handleDisplayIndex(e, 1)}
            style={
              darkMode
                ? { textDecorationColor: "var(--secondary-light-four)" }
                : { textDecorationColor: "var(--main-dark-four)" }
            }
          >
            Help Me Chews
          </div>
          <div
            className="description-nav-item"
            onClick={(e) => handleDisplayIndex(e, 2)}
            style={
              darkMode
                ? { textDecorationColor: "var(--secondary-light-four)" }
                : { textDecorationColor: "var(--main-dark-four)" }
            }
          >
            Cubberd
          </div>
          <div
            className="description-nav-item"
            onClick={(e) => handleDisplayIndex(e, 3)}
            style={
              darkMode
                ? { textDecorationColor: "var(--secondary-light-four)" }
                : { textDecorationColor: "var(--main-dark-four)" }
            }
          >
            Dif-fur-ent Oppawtunity
          </div>
          <div
            className="description-nav-item"
            onClick={(e) => handleDisplayIndex(e, 4)}
            style={
              darkMode
                ? { textDecorationColor: "var(--secondary-light-four)" }
                : { textDecorationColor: "var(--main-dark-four)" }
            }
          >
            Gray Salmon Please
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionNav;
