import "./NavBar.css";
import { SiGmail, SiGithub, SiLinkedin, SiAngellist } from "react-icons/si";
import Signature from "../../assets/signature.svg";
import SignatureWhite from "../../assets/signature-white.svg";
import { useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";

const NavBar = () => {
  const { darkMode } = useContext(DarkModeContext);

  const handleMouseOver = (e) => {
    e.preventDefault();

    darkMode ? (e.target.src = Signature) : (e.target.src = SignatureWhite);
  };

  const handleMouseOut = (e) => {
    e.preventDefault();

    darkMode ? (e.target.src = SignatureWhite) : (e.target.src = Signature);
  };

  return (
    <>
      <div className="navbar-container">
        <a className="navbar-home" href="/">
          {darkMode ? (
            <img
              src={SignatureWhite}
              alt="signature-white"
              onMouseOver={(e) => handleMouseOver(e)}
              onMouseOut={(e) => handleMouseOut(e)}
            />
          ) : (
            <img
              src={Signature}
              alt="signature-black"
              onMouseOver={(e) => handleMouseOver(e)}
              onMouseOut={(e) => handleMouseOut(e)}
            />
          )}
        </a>
        <div
          className="navbar-links"
          style={
            darkMode
              ? {
                  color: "var(--secondary-light-four)",
                }
              : {
                  color: "var(--main-dark-four)",
                }
          }
        >
          <div
            className={darkMode ? "nav-link-dark" : "nav-link-light"}
            onClick={() => (window.location = "mailto:sungyotkim@gmail.com")}
          >
            <SiGmail />
          </div>
          <a
            href="https://github.com/sungyotkim"
            target={"_blank"}
            rel="noreferrer"
            className={darkMode ? "nav-link-dark" : "nav-link-light"}
          >
            <SiGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/sungyotkim/"
            target={"_blank"}
            rel="noreferrer"
            className={darkMode ? "nav-link-dark" : "nav-link-light"}
          >
            <SiLinkedin />
          </a>
          <a
            href="https://angel.co/u/sungyo-tommy-kim"
            target={"_blank"}
            rel="noreferrer"
            className={darkMode ? "nav-link-dark" : "nav-link-light"}
          >
            <SiAngellist />
          </a>
        </div>
      </div>
    </>
  );
};

export default NavBar;
