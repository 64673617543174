import { useContext } from "react";
import { DarkModeContext } from "../../context/DarkModeContext";
import "./TechStacks.css";

const TechStacks = ({ displayIndex, setDisplayIndex }) => {
  const { darkMode } = useContext(DarkModeContext);

  const handleDisplayIndex = (e, index) => {
    e.preventDefault();

    if (displayIndex === index) {
      setDisplayIndex(0);
    } else {
      setDisplayIndex(index);
    }
  };

  return (
    <>
      <div
        className="tech-stacks"
        onClick={(e) => handleDisplayIndex(e, 5)}
        style={darkMode ? { color: "white" } : { color: "black" }}
      >
        Tech Stacks
      </div>
    </>
  );
};

export default TechStacks;
