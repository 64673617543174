import { useState } from "react";
import DescriptionNav from "./DescriptionNav";
import MainDescription from "./MainDescription";
import "./MainDescriptions.css";
import TechStacks from "./TechStacks";

const MainDescriptions = () => {
  const [displayIndex, setDisplayIndex] = useState(0);
  return (
    <>
      <div className="main-descriptions-container">
        <div className="main-descriptions-inner-wrapper">
          <div className="main-descriptions-left-container">
            <MainDescription displayIndex={displayIndex} />
          </div>
          <div className="main-descriptions-right-container">
            <DescriptionNav
              displayIndex={displayIndex}
              setDisplayIndex={setDisplayIndex}
            />
            <TechStacks
              displayIndex={displayIndex}
              setDisplayIndex={setDisplayIndex}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainDescriptions;
